import React, { useState } from "react";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const AppContext = React.createContext();

const AppProvider = ({ children }) => {

  const getDatfromLocalStorage = () =>{
    const data = localStorage.getItem("code")
    if(data){
      const response = JSON.parse(data)
      return response
    }
  }
  const [output, setOutput] = useState(null);
  
  const [code, setCode] = useState(getDatfromLocalStorage()?getDatfromLocalStorage:"#print('hello')" );

  const setCodeTolocalStorage = (code) =>{
    if (code){
      localStorage.setItem("code", JSON.stringify(code))
    }
  }
  const getuserDataFromLocalStorage = () =>{
    const data = localStorage.getItem("user")
    if(data){
      const response = JSON.parse(data)
      
      return response
    }
  }
  const [loading, setloading] = useState(false);
  const handleEditorChange = (value) => {
    setCode(value);
  };
  const postPythonCode = async (url) => {
    const payload = code.replace(/'/g, '"');
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({ code: payload })
    });
    const data = await response.json();
    setCodeTolocalStorage(code)
    setOutput(data.output);
   
  };


  const clearCode = () => {
    return setOutput("");
  };
  const SetLoading = () => {
    setloading(!loading);
  };
  const clearEditor = () =>{
    toast.error("cleared..");
    setCode("")
    localStorage.clear()
    window.location.reload()
  }

  return (
    <AppContext.Provider
      value={{ code, 
        handleEditorChange, 
        postPythonCode, 
        output,
         clearCode ,
          SetLoading,
          clearEditor,
          getuserDataFromLocalStorage}}
    >
      {children}
    </AppContext.Provider>
  );
};

const useGlobalContext = () => {
  return React.useContext(AppContext);
};

export { useGlobalContext, AppProvider };
