import loginPage from '../components/assets/loginPage.png'
import SignupPage from '../components/assets/signup.png'
import Loader1 from '../components/assets/loader1.gif'
import LandingPage from '../components/assets/landingPage.gif'
export const  compData= [
    {
        id:1, 
        heading:`Login component`,
        text:'Beautifull login page source code using HTML and CSS',
        btn_text:"Explore",
        link:'/designcompoent',
        img: loginPage,
        code:`<!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Login Page</title>
            <!-- Include Tailwind CSS CDN -->
            <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet">
        </head>
        <body class="bg-cover bg-center flex flex-col justify-center items-center min-h-screen bg-gradient-to-r from-blue-500 to-purple-500">
            <div class="w-full text-center bg-white bg-opacity-80 p-8 shadow-md" style="position: absolute; top: 0;">
                <h1 class="text-4xl font-bold text-gray-800">toolsbio.com</h1>
            </div>
            <div class="mt-4 text-center text-white">
                <h2 class="text-3xl font-bold mb-4">Login Page</h2>
            </div>
            <div class="mt-4 p-8 bg-white bg-opacity-80 rounded-md shadow-md transform transition-transform hover:scale-105">
                <form class="space-y-4">
                    <input type="text" class="w-full p-4 border border-gray-300 rounded-md" placeholder="Username" required>
                    <input type="password" class="w-full p-4 border border-gray-300 rounded-md" placeholder="Password" required>
                    <button type="submit" class="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">Login</button>
                </form>
            </div>
        </body>
        </html>
        `
    },
    {
        id:2, 
        heading:`Signup component`,
        text:'Beautifull Register page source code using HTML and CSS',
        btn_text:"Explore",
        link:'/designcompoent',
        img: SignupPage,
        code:`<!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Signup Page</title>
            <!-- Include Tailwind CSS CDN -->
            <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet">
        </head>
        <body class="bg-cover bg-center flex flex-col justify-center items-center min-h-screen bg-gradient-to-r from-blue-500 to-purple-500">
            <div class="w-full text-center bg-white bg-opacity-80 p-6 shadow-md" style="position: absolute; top: 0;">
                <h1 class="text-4xl font-bold text-gray-800">toolsbio.com</h1>
            </div>
            <div class="mt-4 text-center text-white">
                <h2 class="text-3xl font-bold mb-2">Signup Page</h2>
            </div>
            <div class="mt-4 p-4 bg-white bg-opacity-80 rounded-md shadow-md transform transition-transform hover:scale-105" style="width: 300px;">
                <form class="space-y-4">
                    <input type="text" class="w-full p-2 border border-gray-300 rounded-md" placeholder="Full Name" required>
                    <input type="email" class="w-full p-2 border border-gray-300 rounded-md" placeholder="Email" required>
                    <input type="text" class="w-full p-2 border border-gray-300 rounded-md" placeholder="Username" required>
                    <input type="password" class="w-full p-2 border border-gray-300 rounded-md" placeholder="Password" required>
                    <button type="submit" class="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">Signup</button>
                </form>
            </div>
        </body>
        </html>
        `
    },
    {
        id:3, 
        heading:`Loader component`,
        text:'Beautifull loading page source code using HTML and CSS',
        btn_text:"Explore",
        link:'/designcompoent',
        img: Loader1,
        code:`<!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Loader Page</title>
            <!-- Include Tailwind CSS CDN -->
            <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet">
            <style>
                body {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100vh;
                    margin: 0;
                    background: linear-gradient(to right, #3498db, #9b59b6);
                }
        
                .loader {
                    display: flex;
                    justify-content: space-between;
                    width: 60px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
        
                .dot {
                    width: 20px;
                    height: 20px;
                    background-color: #fff;
                    border-radius: 50%;
                    animation: bounce 0.5s infinite alternate;
                }
        
                .dot:nth-child(2) {
                    animation-delay: 0.2s;
                }
        
                .dot:nth-child(3) {
                    animation-delay: 0.4s;
                }
        
                @keyframes bounce {
                    to {
                        transform: translateY(-20px);
                    }
                }
            </style>
        </head>
        <body>
            <div class="loader">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>
        
            <div class="w-full text-center bg-white bg-opacity-80 p-6 shadow-md" style="position: absolute; top: 0;">
                <h1 class="text-4xl font-bold text-gray-800">toolsbio.com</h1>
            </div>
        
        
           
        </body>
        </html>
        `   
    },
    {
        id:4, 
        heading:`Landing Page component`,
        text:'Beautifull Landing page source code using HTML and CSS',
        btn_text:"Explore",
        link:'/designcompoent',
        img: LandingPage,
        code:`
        <!DOCTYPE html>
<html lang="en">
  
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <link rel="stylesheet" href="output.css">
  <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet">
  <title>Your Landing Page</title>
  <style>
    @keyframes fadeInUp {
      from {
        opacity: 0;
        transform: translateY(20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .card {
      background-color: #ffffff;
      border-radius: 8px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      padding: 20px;
      animation: fadeInUp 1s ease-out;
    }
  </style>
</head>

<body class="bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 text-white">

  <header class="bg-gray-800 text-white p-4">
    <h1 class="text-2xl font-bold text-center">toolsbio.com</h1>
  </header>

  <section class="p-8">
    <h2 class="text-3xl font-bold mb-4 text-center">Welcome to ToolsBio!</h2>
    <p class="text-lg  text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit. ...</p>

    <!-- Animated Cards -->
    <div class="flex flex-wrap">
        <div class="w-full md:w-1/3 p-4">
          <div class="card mt-8">
            <h3 class="text-xl font-bold mb-2">Card 1</h3>
            <p class="text-center text-gray-700">Explore our tools and resources to enhance your experience!</p>
          </div>
        </div>
        <div class="w-full md:w-1/3 p-4">
          <div class="card mt-8">
            <h3 class="text-xl font-bold mb-2">Card 2</h3>
            <p class="text-gray-700">Explore our tools and resources to enhance your experience!</p>
          </div>
        </div>
        <div class="w-full md:w-1/3 p-4">
          <div class="card mt-8">
            <h3 class="text-xl font-bold mb-2">Card 3</h3>
            <p class="text-gray-700">Explore our tools and resources to enhance your experience!</p>
          </div>
        </div>
      </div>
  </section>
  <!-- Footer with 50vh height -->
  <footer class="bg-gray-800 text-white p-2 h-20vh">
    <!-- Your footer content here -->
  </footer>
</body>
</html>
        `   
    }
]