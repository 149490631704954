import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AppProvider } from './components/context/appcontext';
import { PrimeReactProvider } from 'primereact/api';
import GeneralContextProvider from './components/context/GeneralContextProvider';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AppProvider>
    <GeneralContextProvider>
    <PrimeReactProvider>
    <App />

    </PrimeReactProvider>
    </GeneralContextProvider>
  </AppProvider>
   
  
);

