import React, { useRef, useState, useEffect } from "react";
import Editor from "@monaco-editor/react";
import { useGlobalContext } from "../context/appcontext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import { Dropdown } from "primereact/dropdown";
const PythonCompiler = () => {
  const editorRef = useRef(null);
  const [theme, setTheme] = useState("vs-dark");
  const [fileCount, setFileCount] = useState(1);
  const [fileName, setFileName] = useState(`your_code_1.py`); // Initialize with the default filename
  const {
    code,
    handleEditorChange,
    postPythonCode,
    output,
    clearCode,
    clearEditor,
  } = useGlobalContext();
  const [fontOptions, setFontOptions] = useState([]);

  const getFontSizeFromLocalStorage = () => {
    const f_size = localStorage.getItem("fontsize");
    if (f_size) {
      const size = JSON.parse(f_size);
      return size;
    }

    return 15;
  };

  const [fontSize, setFontSize] = useState(getFontSizeFromLocalStorage());
  const setFontSizetoLocalStorage = () => {
    localStorage.setItem("fontsize", JSON.stringify(fontSize && fontSize));
  };

  useEffect(() => {
    setFontSizetoLocalStorage();
  }, [fontSize]);

  useEffect(() => {
    handleRunClick();
    // eslint-disable-next-line
  }, [fileCount]);

  const handleRunClick = () => {
    postPythonCode(
      `https://notesapi-test.onrender.com/api/compiler/executepython`
    );
    code && output && toast.success("compiling..");
  };

  const handleEditorDidMount = (editor, monaco) => {
    editorRef.current = editor;
  };

  const handleToggleTheme = () => {
    toast.success("Theme Changed");
    setTheme(theme === "vs-dark" ? "vs-light" : "vs-dark");
  };

  const handleNewFile = () => {
    setFileCount((prevCount) => prevCount + 1);
    const newFileName = `your_code_${fileCount + 1}.py`; // Update the filename
    setFileName(newFileName); // Update the filename in the state
    clearCode();
    handleEditorChange("");
    toast.success(`New file created: ${newFileName}`);
  };

  const handleDownloadCode = () => {
    const blob = new Blob([code], { type: "text/plain" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    // eslint-disable-next-line
    const fileNameWithExtension = `${
      fileName.endsWith(".py") ? fileName : fileName + ".py"
    }`;
    a.href = url;
    a.download = fileName; // Use the current filename
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const handleFileNameChange = (event) => {
    setFileName(event.target.value);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-9NPYJSM5SJ`;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "G-9NPYJSM5SJ");
    };
  }, []);

  useEffect(() => {
    setFontOptions(generateFontOptions());
  }, []);

  const generateFontOptions = () => {
    const options = [];
    for (let i = 10; i <= 50; i += 2) {
      options.push({ value: i, label: `${i}px` });
    }
    return options;
  };
  const handleFontSizeChange = (selectedOption) => {
    if (selectedOption) {
      const selectedValue = selectedOption.value;
      setFontSize(selectedValue);
    }
  };

  return (
    <div className="flex flex-col h-screen">
      <Helmet>
        <title>Online Python Compiler - toolsbio.com</title>
        <meta
          name="description"
          content="Write, compile, and run Python code online. Your go-to Python compiler on toolsbio.com."
        />
        <meta
          name="keywords"
          content="online Python editor, Python code compiler, code editor, toolsbio.com, coding tools, Python programming, online coding, Python IDE, Python compiler, code execution, interactive Python, web-based Python, Python scripting, programming environment, Python coding playground, syntax highlighting, collaborative coding, Python development"
        />
        <meta name="author" content="mehruddin khan" />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href="https://toolsbio.com/python" />
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2030818425983269"
          crossorigin="anonymous"
        ></script>
      </Helmet>

      <ToastContainer position="top-right" autoClose={2000} />

      {/* Header */}
      <div className="bg-gray-800 text-white p-2">
        {/* <div>
          <h3 className="bg-green-500 w-40"> Python Compiler</h3>
        </div> */}

        <div className="flex flex-wrap justify-center space-y-2 md:space-y-0 md:space-x-4 mt-2">
          <button
            className="bg-red-500 text-white hover:bg-green-600 py-2 px-4 rounded-lg w-full md:w-auto"
            onClick={clearEditor}
          >
            Clear code
          </button>
          <button
            className="bg-blue-500 text-white hover:bg-white-400 py-2 px-4 rounded-lg w-full md:w-auto"
            onClick={handleToggleTheme}
          >
            {theme === "vs-dark" ? (
              <span role="img" aria-label="Sun Emoji">
                ☀️
              </span>
            ) : (
              <span role="img" aria-label="Moon Emoji">
                🌙
              </span>
            )}
            Change Theme
          </button>
          <button
            className="bg-green-500 text-white hover:bg-green-600 py-2 px-4 rounded-lg w-full md:w-auto"
            onClick={handleRunClick}
          >
            Run
          </button>
          <button
            className="bg-yellow-500 text-white hover:bg-yellow-600 py-2 px-4 rounded-lg w-full md:w-auto"
            onClick={handleNewFile}
          >
            New File
          </button>
          <button
            className="bg-purple-500 text-white hover:bg-purple-600 py-2 px-4 rounded-lg w-full md:w-auto"
            onClick={handleDownloadCode}
          >
            Download Code
          </button>
          <div className="flex items-center space-x-2">
            <label className="text-white">Filename:</label>
            <input
              type="text"
              value={fileName}
              onChange={handleFileNameChange}
              className="bg-gray-700 text-white p-1 rounded"
            />
          </div>
        </div>
      </div>

      {/* Editor and Output Section */}
      <div className="flex-1 flex flex-col md:flex-row editor">
        {/* Left editor window */}
        <div className="w-full md:w-1/2 py-editor">
          {/* Display the current file name */}
          <div></div>

          <div
            className="
                text-black p-0 "
          >
            {`File: ${fileName}`}
            <span style={{ marginRight: "-1000px" }}>
              {" "}
              <Dropdown
                onChange={handleFontSizeChange}
                placeholder={"font Size " + fontSize}
                options={fontOptions}
              />
            </span>
          </div>

          <Editor
            height="90vh"
            width="100%"
            defaultLanguage="python"
            value={code}
            theme={theme}
            onChange={handleEditorChange}
            options={{
              automaticLayout: true,
              scrollbar: {
                useShadows: false,
                verticalHasArrows: true,
                horizontalHasArrows: true,
              },
              mouseWheelZoom: true,
              fontSize: fontSize + "px", // Set the font size dynamically
            }}
          />
        </div>
        {/* Right output window */}
        <div
          className="flex-1 resize-x bg-gray-300 overflow-scroll py-output"
          style={{ zIndex: "100" }}
        >
          {output && (
            <button
              onClick={clearCode}
              className="bg-red-0 hover:bg-red-300 text-red font-bold py-2 px-2 rounded w-full mb-2 md:mb-0"
              style={{ color: "red" }}
            >
              Clear
            </button>
          )}
          <div
            className="overflow-y-scroll h-full py-output"
            style={{ overflow: "scroll", maxHeight: "90vh" }}
          >
            <pre>
              {output &&
                output.split("\n").map((line, index) => (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                ))}
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PythonCompiler;
