import React, { useState,useEffect } from 'react';
// eslint-disable-next-line
import { API_URL, API_URL_DEPLOYED } from '../../environment/environement';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
// import './SignUp.css'; x// Add a CSS file for styling
import { InputText } from 'primereact/inputtext';
import { Helmet } from 'react-helmet';
const SignUp = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [touchedFields, setTouchedFields] = useState({
    username: false,
    email: false,
    password: false,
  });

  const navigate = useNavigate();

  const handleFieldBlur = (fieldName) => {
    setTouchedFields({ ...touchedFields, [fieldName]: true });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Validation: Check if any of the fields are empty and if they have been touched
      if (!username || !email || !password) {
        toast.error('All fields are required');
        return;
      }

      const response = await fetch(`${API_URL_DEPLOYED}/auth/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, email, password }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Signup successful:', data);
        toast.success('Signed up');
       
          navigate('/login');
        

      } else {
        toast.error("Dplicate Email")
      }
    } catch (error) {
      console.error('Signup error:', error);
    }
  };
  useEffect(() => {
 
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-9NPYJSM5SJ`;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "G-9NPYJSM5SJ");
    };
  }, []);


  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <Helmet>
      <title>SignUp Online Tools - toolsbio.com</title>
      <meta name="description" content="Login to access exclusive features on toolsbio.com." />
      <meta name="keywords" content="login, authentication, user access, toolsbio.com" />
      <meta name="robots" content="index, follow" />
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2030818425983269" crossorigin="anonymous"></script>

    </Helmet>
      <ToastContainer position="top-right" autoClose={1000} />
      <div className="bg-white p-6 md:p-10 rounded-lg shadow-md w-full md:w-1/2">
        <h1 className="text-2xl md:text-3xl font-semibold text-center mb-4">
          Sign Up
        </h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="username"
              className={`block text-gray-700 font-medium mb-2 ${
                touchedFields.username && !username ? 'text-red-500' : ''
              } hidden md:block`}
            >
              Username
            </label>
            <span className="p-inputgroup-addon">
        <i className="pi"></i>
        <InputText
              type="text"
              id="username"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              onBlur={() => handleFieldBlur('username')}
              placeholder="Enter your username"
              className={`w-full px-3 py-2 border border-gray-300 rounded focus:outline-none ${
                touchedFields.username && !username ? 'border-red-500' : ''
              }`}
              required
            />
          </span>
           
          </div>
          <div className="mb-4">
            <label
              htmlFor="email"
              className={`block text-gray-700 font-medium mb-2 ${
                touchedFields.email && !email ? 'text-red-500' : ''
              } hidden md:block`}
            >
              Email
            </label>
            <span className="p-inputgroup-addon">
        <i className="pi pi-email"></i>
        <InputText
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onBlur={() => handleFieldBlur('email')}
              placeholder="Enter your email"
              className={`w-full px-3 py-2 border border-gray-300 rounded focus:outline-none ${
                touchedFields.email && !email ? 'border-red-500' : ''
              }`}
              required
            />
    </span>
           
          </div>
          <div className="mb-4">
            <label
              htmlFor="password"
              className={`block text-gray-700 font-medium mb-2 ${
                touchedFields.password && !password ? 'text-red-500' : ''
              } hidden md:block`}
            >
              Password
            </label>
            <span className="p-inputgroup-addon">
        <i className="pi "></i>
        <InputText
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onBlur={() => handleFieldBlur('password')}
              placeholder="Enter your password"
              className={`w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500 ${
                touchedFields.password && !password ? 'border-red-500' : ''
              }`}
              required
            />
    </span>
            
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
            >
              Sign Up
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
