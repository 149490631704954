import React, { useState, useEffect } from 'react';
import { FaGithub, FaInstagram, FaRegCopyright } from 'react-icons/fa';

const Footer = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    const handleClickOutsideModal = (event) => {
      if (showModal && !document.getElementById('modal-container').contains(event.target)) {
        handleCloseModal();
      }
    };

    // Add event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutsideModal);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideModal);
    };
  }, [showModal]);
  return (
    <footer className="bg-gray-900 text-white py-8" style={{ zIndex: '1000' }}>
      <div id="modal-container" className="container mx-auto flex flex-col md:flex-row justify-between items-center">
        <div className="mb-4 md:mb-0">
          <h3 className="text-2xl font-bold">Online Tools</h3>
          {/* <p className="text-sm">made by mehruddin with ❤️</p> */}
        </div>
        <div className="flex space-x-4">
          <a
            href="https://github.com/mehruddin11"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-300 hover:text-white"
          >
            <FaGithub size={24} />
          </a>
          {/*  */}
          <a
            href="https://instagram.com/coding_challenge_"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-300 hover:text-white"
          >
            <FaInstagram size={24} />
          </a>
        </div>
      </div>

      <div className="text-center mt-4">
        <p className="text-sm flex items-center justify-center">
          <FaRegCopyright className="mr-1" />
          2023 Online Tools . All Rights Reserved.
        </p>
        <button
          onClick={handleShowModal}
          type="button"
         
          data-toggle="modal"
          data-target="#exampleModalLong"
        >
          Our Privacy Policy
        </button>

        {/* Privacy Policy Modal */}
        {showModal && (
          <div className="fixed inset-0 z-10 flex items-center justify-center">
            <div className="modal-overlay absolute w-full h-full bg-gray-800 opacity-50"></div>
            <div className="modal-container bg-white w-[100%] md:max-w-md mx-auto rounded shadow-lg z-50 overflow-x-auto overflow-y-auto">
              <div className="modal-content py-4 text-left px-6">
                <div className="modal-header mb-4">
                  <h5 className="modal-title text-2xl font-semibold">Our Privacy Policy</h5>
                  <button
                    type="button"
                    className="close text-white opacity-70 hover:opacity-100"
                    onClick={handleCloseModal}
                  >
                    <span>&times;</span>
                  </button>
                </div>
                <div className="modal-body max-h-screen overflow-y-auto">
                  {/* Your privacy policy content goes here */}
                  <div className="text-black mx-auto overflow-x-auto overflow-y-auto">

                    <p>
                      <strong>Privacy Policy</strong>
                    </p>
                    <p>
                      <strong>Last Updated: October 2, 2023</strong>
                    </p>
                    <p>
                      Welcome to ToolsBio ("us," "we," or "our")! At ToolsBio, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your personal information.
                    </p>
                    <p>
                      <strong>1. Information We Collect</strong>
                    </p>
                    <ul>
                      <li>
                        <strong>Personal Information:</strong> We may collect personal information such as your name, email address, and other contact details when you register, create an account, or use our services.
                      </li>
                      <li>
                        <strong>Usage Information:</strong> We gather information about how you interact with our website and tools, including pages visited, features used, and other user activities.
                      </li>
                    </ul>
                    <p>
                      <strong>2. How We Use Your Information</strong>
                    </p>
                    <ul>
                      <li>
                        <strong>Service Delivery:</strong> We use your information to provide, maintain, and improve our tools and services.
                      </li>
                      <li>
                        <strong>Personalization:</strong> We may personalize your experience by analyzing your usage patterns and providing tailored content and recommendations.
                      </li>
                      <li>
                        <strong>Communication:</strong> We may contact you with relevant updates, announcements, and marketing materials.
                      </li>
                    </ul>
                    <p>
                      <strong>3. Cookies and Similar Technologies</strong>
                    </p>
                    <p>
                      We use cookies and similar technologies to enhance user experience, analyze usage patterns, and customize content.
                    </p>
                    <p>
                      <strong>4. Third-Party Services</strong>
                    </p>
                    <p>
                      We may utilize third-party services, such as analytics tools, to gather and analyze user data for improving our services.
                    </p>
                   
                    <p>
                      Thank you for choosing ToolsBio!
                    </p>
                  </div>

                </div>
                <div className="modal-footer mt-4">
                  <p
                    type="button"
                    className="btn btn-secondary bg-gray-500 text-white py-2 px-4 rounded"
                    onClick={handleCloseModal}
                  >
                    Close
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>


    </footer>
  );
};

export default Footer;
