import React, { useState , useEffect} from 'react';
// eslint-disable-next-line
import { API_URL,API_URL_DEPLOYED } from '../../environment/environement';
// import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InputText } from 'primereact/inputtext';
import { Helmet } from 'react-helmet';
const Login = () => {
  // Define state variables for email and password
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const  navigate = useNavigate();
  // Handle form submission
 
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${API_URL_DEPLOYED}/auth/login`, { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }), 
      });
      if (response.ok) {
        const data = await response.json()
        // console.log(data.message)
        toast.success("logged  in")
        localStorage.setItem('user', JSON.stringify(data.user));
        // console.log("data ", data.user)

       
          navigate('/');
        

      } else {
        console.error('Login failed:', response.statusText);
        toast.error("unotherized")
      }
    } catch (error) {
      console.error('Login error:', error);
    }
  };
  useEffect(() => {
 
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-9NPYJSM5SJ`;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "G-9NPYJSM5SJ");
    };
  }, []);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100" style={{ marginTop: '-12px' }}>
          <Helmet>
          <title>Login Online Tools - toolsbio.com</title>
      <meta name="description" content="Login to access exclusive features on toolsbio.com." />
      <meta name="keywords" content="login, authentication, user access, toolsbio.com" />
      <meta name="robots" content="index, follow" />
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2030818425983269" crossorigin="anonymous"></script>

    </Helmet>

       <ToastContainer position="top-right" autoClose={1000} />
      <div className="bg-white p-6 md:p-10 rounded-lg shadow-md w-full md:w-1/2">
        <h1 className="text-2xl md:text-3xl font-semibold text-center mb-4">Login Page</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700 font-medium mb-2 hidden md:block">Email</label>
            <span className="p-inputgroup-addon">
              <i className="pi pi-user"></i>
              <InputText
            
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
            </span>
            
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="block text-gray-700 font-medium mb-2 hidden md:block">Password</label>
            <span className="p-inputgroup-addon">
        <i className="pi pi-password"></i>
        <InputText
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
           </span>
            {/* <InputText
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            /> */}
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
