import React from 'react';
import { Link } from 'react-router-dom';

const ErrorPage = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-funny-image bg-cover">
      <div className="bg-white p-8 rounded-lg shadow-md">
        <h1 className="text-4xl font-bold text-center text-red-600">Oops! Something went wrong</h1>
        <p className="text-lg text-center mt-4">It seems like we hit a hiccup. Don't worry; our team of highly trained monkeys is working to fix it!</p>
        <div className="mt-8 text-center">
          <Link to ="/" className="text-blue-500 hover:underline">
            <h4> Go back to the homepage </h4> 
            </Link>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
