import React, { useState, useEffect } from "react";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { Link } from "react-router-dom";
import Cards from "../cards/card";
import Footer from "../fotter/fotter";
// eslint-disable-next-line
import { API_URL, API_URL_DEPLOYED } from "../environment/environement";
import Avatar from "react-avatar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useGlobalContext } from "../context/appcontext";
//prime react
import { InputText } from "primereact/inputtext";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Helmet } from "react-helmet";
import { compData } from "../../comdata.js/data";
import { serviceData } from "../../comdata.js/servciceData";
// import DesignComponent from "../designComponents/DesignComponents";

const Home = () => {
  const { getuserDataFromLocalStorage } = useGlobalContext();
  const data = getuserDataFromLocalStorage();
  const [username, setUsername] = useState(data && data.username);
  const [userFeedback, setUserFeedback] = useState("");
  const [feedbackList, setFeedbackList] = useState([]);
  const isUserLoggedIn = localStorage.getItem("user") !== null;
  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleFeedbackChange = (e) => {
    setUserFeedback(e.target.value);
  };

  const submitFeedback = () => {
    if (userFeedback && isUserLoggedIn) {
      postReview({ username, feedbackText: userFeedback });
      setUserFeedback("");
      setUsername("");
      toast.success("review added");
      return;
    }
    if (!userFeedback && isUserLoggedIn) {
      toast.error("please fill the form");
    } else {
      toast.error("Please Login First");
    }
  };

  const postReview = async (feedbackData) => {
    try {
      const response = await fetch(`${API_URL_DEPLOYED}/feedback/post`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(feedbackData),
      });

      if (response.ok) {
        // const data = await response.json();
        // console.log("Feedback posted successfully:", data);
        getReview();
      } else {
        console.error("Feedback post failed:", response.statusText);
      }
    } catch (error) {
      console.error("Feedback post error:", error);
    }
  };

  const getReview = async () => {
    try {
      const response = await fetch(`${API_URL_DEPLOYED}/feedback/get`);

      if (response.ok) {
        const data = await response.json();
        setFeedbackList(data);
        // console.log("Feedback retrieved successfully:", data);
      } else {
        console.error("Feedback retrieval failed:", response.statusText);
      }
    } catch (error) {
      console.error("Feedback retrieval error:", error);
    }
  };
  // useEffect(() => {
  //   // Destroy existing ad slots
  //   window.adsbygoogle = window.adsbygoogle || [];
  //   window.adsbygoogle.push({});

  //   return () => {
  //     // Clean up when the component is unmounted
  //   };
  // }, [/* dependencies */]);

  // useEffect(() => {

  //   const script = document.createElement("script");
  //   script.async = true;
  //   script.src = `https://www.googletagmanager.com/gtag/js?id=G-9NPYJSM5SJ`;
  //   document.head.appendChild(script);

  //   script.onload = () => {
  //     window.dataLayer = window.dataLayer || [];
  //     function gtag() {
  //       window.dataLayer.push(arguments);
  //     }
  //     gtag("js", new Date());
  //     gtag("config", "G-9NPYJSM5SJ");
  //   };
  // }, []);

  useEffect(() => {
    getReview();
  }, []);

  //   useEffect(() => {
  //   (window.adsbygoogle = window.adsbygoogle || []).push({});
  // }, []);

  return (
    <div className=" min-h-screen">
      <meta
        name="google-adsense-account"
        content="ca-pub-2030818425983269"
      ></meta>
      <Helmet>
        <title>Home - toolsbio.com</title>
        <meta
          name="description"
          content="Calculate the complexity of algorithms with the online Complexity Calculator on toolsbio.com."
        />
        <meta
          name="keywords"
          content="complexity calculator, algorithm complexity, toolsbio.com, coding, 
              toolsbio, tool, tools bio, tools bio, python compiler online, code compiler, complexity 
              calculator tool, image to PDF converter, JSON formatter, decimal to binary converter, programming 
              tools, coding utilities, online coding tools, algorithm analysis, programming services, toolsbio.com, 
              coding platform, code optimization, programming resources, web development tools, software engineering, 
              code formatting, online coding environment, programming utilities"
              />
        <meta name="author" content="mehruddin khan" />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href="https://toolsbio.com/" />
        {/* Add other meta tags as needed for SEO */}
      </Helmet>

      <ToastContainer position="top-right" autoClose={2000} />
      <div className=" min-h-screen flex flex-col items-center justify-center">
        {/* Header */}
        <header className="header-text text-white mb-10 mt-10">
          <h1
            className="text-4xl font-bold mb"
            style={{ color: "black", marginTop: "1rem" }}
          >
            Welcome to Online Tools
          </h1>

          <Link to="/python"></Link>
        </header>
        {/* css components */}
        <div className="bg-green-100 p-2 w-full sm:w-4/5 mt-10 text-center">
          <p className="text-xl text-green-800 font-semibold text-4xl">
            CSS Components
          </p>
        </div>
        {/* comp data */}
        <div className=" card-style">
          {compData.map((res) => {
            const { id, heading, text, btn_text, link, img } = res;
            return (
              <Cards
                key={id}
                heading={heading}
                text={text}
                btn_text={btn_text}
                link={`${link}/${id}`}
                img={img}
                sty={"card"}
              />
            );
          })}
        </div>

        {/* Service Section */}

        <div
          className="bg-green-100 p-2 w-full sm:w-4/5 mt-10 text-center"
          style={{ borderRadius: "0.6rem" }}
        >
          <p className="text-xl text-green-800 font-semibold text-4xl">
            Explore our services
          </p>
        </div>

        {/* Cards Section */}
        <div className="grid-card">
          {serviceData.map((res) => {
            const { id, heading, text, btn_text, link, img } = res;
            return (
              <>
                <Cards
                  sty={"card"}
                  key={id}
                  heading={heading}
                  text={text}
                  btn_text={btn_text}
                  link={link}
                  img={img}
                />
              </>
            );
          })}
        </div>

        {/* User Feedback Section */}
        <div
          className="w-full sm:w-4/5 mt-8 mx-auto "
          style={{ textAlign: "center" }}
        >
          <h5 className="text-2xl font-semibold text-black-600 mb-4 ">
            Share Your Feedback and Suggestions
          </h5>

          {/* Feedback Form */}
          <div className="w-full max-w-lg mx-auto mt-4">
            {/* Name Input */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 hidden sm:inline">
                Your Name
              </label>
              <InputText
                type="text"
                placeholder="Enter your name"
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                value={username}
                onChange={handleUsernameChange}
              />
            </div>

            {/* Feedback Textarea */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 hidden sm:inline">
                Your Feedback
              </label>
              <InputTextarea
                rows="4"
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                placeholder="Enter your feedback or suggest new tools..."
                value={userFeedback}
                onChange={handleFeedbackChange}
              ></InputTextarea>
            </div>

            {/* Submit Button */}
            <div className="text-center">
              <Button
                onClick={submitFeedback}
                className="bg-indigo-500 text-white py-2 px-4 rounded hover:bg-indigo-700 focus:outline-none focus:shadow-outline-blue active:bg-indigo-800"
              >
                Submit Feedback
              </Button>
            </div>
          </div>
        </div>
        {/* end of feedback form */}

        {/* Display review Section */}

        <div className="w-full sm:w-4/5 mt-8 bg-gradient-to-r  p-6 rounded-lg">
          <h4 className="text-2xl font-semibold text-black mb-4">
            User Feedback
          </h4>
          <ul className="text-white">
            {feedbackList.map((feedback, index) => (
              <li
                key={index}
                className="mb-4 flex items-start animate-fade-in bg-white bg-opacity-70 p-4 rounded-md hover:shadow-md transition-all duration-300"
              >
                <Avatar
                  name={feedback.username}
                  size={40}
                  round={true}
                  className="mr-3"
                />
                <div>
                  <div className="mb-1">
                    <strong className="text-blue-500">
                      {feedback.username}:
                    </strong>{" "}
                    <span className="text-black">{feedback.feedbackText}</span>
                  </div>
                  <button
                    className="text-blue-500 hover:underline focus:outline-none"
                    // onClick={() => handleReplyClick(index)}
                  >
                    Reply
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
        {/* end of display review */}
      </div>
      <ins
        // google.com, pub-2030818425983269, DIRECT, f08c47fec0942fa0
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="pub-2030818425983269"
        data-ad-slot="f08c47fec0942fa0"
        data-ad-format="auto"
      />
      <div className="mt-3">
        <Footer />
      </div>
    </div>
  );
};
export default Home;
