import React, { useState , useEffect} from 'react';
import './editor.css';
// import SideBarSide from '../sidebar/sidebar';
import { Helmet } from 'react-helmet';
const Converter = () => {
  const [inputValue, setInputValue] = useState('');
  const [result, setResult] = useState('');
  const [conversionType, setConversionType] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleConvert = () => {
    switch (conversionType) {
      case 'decimalToBinary':
        setResult(Number(inputValue).toString(2));
        break;
      case 'binaryToDecimal':
        setResult(parseInt(inputValue, 2).toString());
        break;
      case 'binaryToHex':
        setResult(parseInt(inputValue, 2).toString(16));
        break;
      case 'hexToDecimal':
        setResult(parseInt(inputValue, 16).toString());
        break;
      default:
        setResult('');
    }
  };

  useEffect(() => {
 
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-9NPYJSM5SJ`;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "G-9NPYJSM5SJ");
    };
  }, []);


  return (
    <div style={{ display: 'flex', width: '100vw', height: '100vh' }}>
            <Helmet>
        <title>Binary and Decimal Converter - toolsbio.com</title>
        <meta
          name="description"
          content="Convert between binary and decimal numbers with the online Binary and Decimal Converter on toolsbio.com."
        />
        <meta name="keywords" content="binary to decimal, decimal to binary, number converter, toolsbio.com, coding tools" />
        <meta name="author" content="mehruddin khan" />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href="https://toolsbio.com/converter" />
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2030818425983269" crossorigin="anonymous"></script>

        {/* Add other meta tags as needed for SEO */}
      </Helmet>

      {/* <SideBarSide /> */}
      <div className="flex flex-col items-center justify-center" style={{ flex: 1, backgroundColor: '#FDF2EF' }}>
        <h1 className="text-3xl font-semibold text-center text-blue-600 bg-gray-100 rounded-lg shadow-md mb-10">
          Online Convert
        </h1>
        <div className="p-20 border rounded-lg shadow-lg text-center custom-padding" style={{ backgroundColor: 'white' }}>
          <div className="mb-4 form-responsive">
            <label htmlFor="conversionType" className="font-semibold mb-2">
              Select Type:
            </label>
            <select
              id="conversionType"
              className="border border-gray-300 p-2 rounded-md w-40"
              value={conversionType}
              onChange={(e) => setConversionType(e.target.value)}
            >
              <option value="">Select...</option>
              <option value="decimalToBinary">Decimal to Binary</option>
              <option value="binaryToDecimal">Binary to Decimal</option>
              <option value="binaryToHex">Binary to Hex</option>
              <option value="hexToDecimal">Hex to Decimal</option>
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="inputValue" className="font-semibold mb-2">
              Enter number:
            </label>
            <input
              type="text"
              id="inputValue"
              className="border border-gray-300 p-2 rounded-md"
              placeholder="Enter a number"
              value={inputValue}
              onChange={handleInputChange}
            />
          </div>
          <button className="px-4 py-2 bg-blue-500 text-white rounded-md mb-4" onClick={handleConvert}>
            Convert
          </button>
          {result && (
            <div className="font-semibold">
              Result: <span className="text-green-500">{result}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Converter;
