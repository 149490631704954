import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { compData } from '../../comdata.js/data';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from '../fotter/fotter';
import { Helmet } from 'react-helmet';

const SingleDesignComponent = () => {
  const { id } = useParams();
  const designData = compData.find(item => item.id === Number(id));

  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(designData.code).then(
      () => {
        setIsCopied(true);
        toast.success('Code has been copied to the clipboard!', {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      },
      err => {
        console.error('Unable to copy text: ', err);
      }
    );
  };

  useEffect(() => {
 
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-9NPYJSM5SJ`;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "G-9NPYJSM5SJ");
    };
  }, []);

  if (!designData) {
    return <div>Design not found</div>;
  }

  return (
    <div>
      <Helmet>
        <title>Css components - toolsbio.com</title>
        <meta
          name="description"
          content="Explore a variety of CSS components on toolsbio.com. Enhance your web development projects with our collection of stylish and functional components."
        />
        <meta
          name="keywords"
          content="CSS components, web development, UI components, toolsbio.com, design elements, front-end development, styling, components library"
        />
        <meta name="author" content="Mehruddin Khan" />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href={`https://toolsbio.com/designcompoent${id}`} />
        <meta property="og:title" content="Css components - toolsbio.com" />
        <meta
          property="og:description"
          content="Explore a variety of CSS components on toolsbio.com. Enhance your web development projects with our collection of stylish and functional components."
        />
        <meta property="og:image" content="https://example.com/your-image.jpg" /> {/* Replace with an actual image URL */}
        <meta property="og:url" content={`https://toolsbio.com/designcompoent${id}`} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2030818425983269"
          crossorigin="anonymous"
        ></script>
      </Helmet>
      
      <div className="max-w-screen-lg mx-auto p-6 bg-white rounded-md shadow-md text-center">
        <h2 className="text-3xl font-bold mb-4">{designData.heading}</h2>

        {/* Display the image */}
        <img src={designData.img} alt={`Design ${designData.id}`} className="mb-4 max-w-full rounded-md mx-auto" />

        {/* Copy Button */}
       
       

        {/* Display the code */}
        <div className="bg-gray-200 p-4 rounded-md">
        <div style={{display:'flex' , justifyContent:'flex-end', marginBottom:'-22px', zIndex:'100'}}>
        <button onClick={handleCopyClick} className=" hover:bg-blue-700 text-black font-bold py-2 px-4 rounded-full mb-4 copy-button">
        {isCopied ? 'Copied!' : 'Copy Code'}
        </button>
        </div>
          <SyntaxHighlighter language="html" style={materialDark}>
            {designData.code}
          </SyntaxHighlighter>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default SingleDesignComponent;
