import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Home, SideBar ,PythonCompiler,JsonFormatter,Converter,About,
   ComplexityCalculator,ErrorPage, Login, SignUp,PdfToImage, RemoveBg,SingleDesignComponent} from './components';

        
function App() {
  return (
    <Router>
      <SideBar />
      <Routes>
        <Route path="/" element={  <Home />  } />
        <Route path="/python" element={<PythonCompiler/>} />
        <Route path="/JsonFormatter" element ={<JsonFormatter/>}/>
        <Route path="/converter" element ={<Converter/>}/>
        <Route path="/about" element ={<About/>}/>
        <Route path="/complexitycalculator" element ={<ComplexityCalculator/>}/>
        <Route path="/login" element ={<Login/>}/>
        <Route path="/signup" element ={<SignUp/>}/>
        <Route path="/pdftoimage" element ={<PdfToImage/>}/>
        <Route path="/removebg" element ={<RemoveBg/>}/>
        {/* <Route path="/pdftoword" element ={<FileUpload/>}/> */}
        <Route path="/designcompoent/:id" element={<SingleDesignComponent />} />
        <Route path="/*" element ={<ErrorPage/>}/>

      </Routes>
    </Router>
  );
}

export default App;
