import React, { useState, useEffect } from 'react';
import Editor from "@monaco-editor/react";
import { API_URL ,API_URL_DEPLOYED} from "../environment/environement";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from 'react-helmet';
function CodeComplexityCalculator() {
  const [code, setCode] = useState('');
  const [complexity, setComplexity] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState('javascript'); // Default language
  
  // Function to map complexity values to Big O notation
  const mapComplexityToBigO = (complexityValue) => {
    switch (complexityValue) {
      case 1:
        return 'O(1)';
      case 2:
        return 'O(n)';
      case 3:
        return 'O(n^2)';
      case 4:
        return 'O(n^3)';
      default:
        return 'Unknown';
    }
  };
  // React.useEffect(()=>{
   
  //   handleCalculate() 
  //   // eslint-disable-next-line
  // }, [])

  const handleChange = (event) => {
    const { value } = event.target; // Get the selected value from the event
    // console.log(value);
    setSelectedLanguage(value);
    toast.success("language changed")
  }

  const handleCalculate = async () => {
    try {
      // console.log(`${API_URL_DEPLOYED}/timecomplexity/${selectedLanguage}`)
      const response = await fetch(`${API_URL_DEPLOYED}/timecomplexity/${selectedLanguage}`, {
       
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code, language: selectedLanguage }), 
      });

      if (response.ok) {
        const data = await response.json();
        console.log("data", data)
        setComplexity(data.complexity);
      }
    } catch (error) {
      console.error('Error calculating complexity:', error);
    }
  };
  useEffect(() => {
 
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-9NPYJSM5SJ`;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "G-9NPYJSM5SJ");
    };
  }, []);


  return (
    <div className="container mx-auto p-4 border rounded-lg bg-gray-100 complexity-container">
          <Helmet>
          <title>Complexity Calculator - toolsbio.com</title>
          <meta
            name="description"
            content="Calculate the complexity of algorithms with the online Complexity Calculator on toolsbio.com."
          />
          <meta name="keywords" content="complexity calculator, algorithm complexity, toolsbio.com, coding tools" />
          <meta name="author" content="mehruddin khan" />
          <meta name="robots" content="index, follow" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <link rel="canonical" href="https://toolsbio.com/complexitycalculator" />
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2030818425983269" crossorigin="anonymous"></script>

          {/* Add other meta tags as needed for SEO */}
        </Helmet>

      <ToastContainer position="top-right" autoClose={1000} />
      <h1 className="text-2xl font-bold mb-4">Code Complexity Calculator</h1>
      <div className="mb-4">
        <label className="mr-2">Select Language:</label>
        <select
          className="bg-white border border-gray-300 rounded py-2 px-4"
          onChange={handleChange} // Pass the handleChange function
          value={selectedLanguage}
        >
          <option value="javascript">JavaScript</option>
          {/* <option value="python">Python</option> */}
          <option value="java">Java</option>
          {/* <option value="cpp">C++</option> */}
        </select>
      </div>
      <Editor
        className='complexity-container'
        height="50vh"
        width="100%"
        language={selectedLanguage} // Use the selected language
        defaultValue=""
        value={code}
        onChange={(newValue) => setCode(newValue)}
      />
      <button
        className=" margin-between bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded cursor-pointer"
        onClick={handleCalculate}
      >
        Calculate Complexity
      </button>
      {complexity !== null && (
        <div className=" margin-between text-xl">
          <p>Code Complexity:  {mapComplexityToBigO(complexity)}</p>
        </div>
      )}
    </div>
  );
}

export default CodeComplexityCalculator;
