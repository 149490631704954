import RemoveBg from '../components/assets/remove_bg.jpeg'
import ImageToPdf from '../components/assets/image_to_pdf.jpeg'
import PyCompiler from '../components/assets/pyCompiler.png'
import JsonFormatter2 from '../components/assets/jsonFormatter2.png'
import BinarytoDecimal from '../components/assets/binart_to_decimal1.jpeg'
import CodeComeplexityImg from '../components/assets/complexityCalculator.png'
export const serviceData = [
    {
      id: 1,
      heading: 'Remove Background From image',
      text: 'Say goodbye to background hassles.',
      btn_text: 'Explore',
      link: '/removebg',
      img: RemoveBg,
    },
    {
      id: 2,
      heading: 'Image to PDF converter',
      text: 'Convert multiple images into PDF anytime.',
      btn_text: 'Explore',
      link: '/pdftoimage',
      img: ImageToPdf,
    },
    {
      id: 3,
      heading: 'Open Python Compiler',
      text: 'Compile and run Python code online, anytime, anywhere.',
      btn_text: 'Explore',
      link: '/python',
      img: PyCompiler,
    },
    {
      id: 4,
      heading: 'Open JSON Formatter',
      text: 'Format and validate JSON data online with ease anytime.',
      btn_text: 'Explore',
      link: '/JsonFormatter',
      img: JsonFormatter2,
    },
    {
      id: 5,
      heading: 'Decimal to Binary converter',
      text: 'Convert (bin to dec), (dec to bin), (bin to hex), (hex to dec)',
      btn_text: 'Explore',
      link: '/converter',
      img: BinarytoDecimal,
    },
    {
      id: 6,
      heading: 'Complexity Calculator',
      text: 'Calculate the complexity of any program accurately anytime.',
      btn_text: 'Explore',
      link: '/complexitycalculator',
      img: CodeComeplexityImg,
    },
  ];