import { Link } from "react-router-dom";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';

const Cards = ({ heading, text, btn_text, link, img, sty }) => {
 
  return (
 
    <div style={{ marginRight: '1.7rem' }} className={sty}>
   
      <Card className="max-w-sm mx-auto p-4 bg-white rounded-lg shadow-lg mt-10 hover:shadow-xl transition duration-300">
        {img && <img  src={img} alt="" className="object-cover w-full h-48 rounded-t-lg mb-4 img-hover" />}
        <h2 className="text-2xl font-semibold mb-2">{heading}</h2>
        <p className="text-gray-700 mb-2">{text}</p>
        <Link to={link}>
          <div style={{ textAlign: 'center' }}>
            <Button label={btn_text} className="p-button-raised p-button-rounded bg-indigo-500 text-white" />
          </div>
        </Link>
      </Card>
    </div>
  );
}

export default Cards;
