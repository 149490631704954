import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { RxHamburgerMenu } from 'react-icons/rx';
import Avatar from 'react-avatar'; // Import the Avatar library
import './header.css';
import { Dropdown } from 'primereact/dropdown';
        
const SideBar = () => {
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const handleSelectChange = (selectedOptions) => {
    navigate(selectedOptions.value);
    // console.log(selectedOptions.value)
    // if (selectedOptions.length >= 0) {
    //   const selectedValue = selectedOptions.value;
    //   console.log("value",selectedValue)
    //   navigate(selectedValue);
    //   closeSidebar();
    // }
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const changeSideBarState = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = () => {
    // Clear user information from local storage
    localStorage.removeItem('user');

    navigate('/login'); // Redirect to the login page after logout
  };
  

  // Retrieve the username from local storage
  const userData = JSON.parse(localStorage.getItem('user'));
  const username = userData ? userData.username : '';

  return (
    <>
      <div className=" py-4" style={{backgroundColor:'#111827'}}>
        <div className="container mx-auto flex justify-between items-center">
          <div className="flex items-center">
            <Link to="/">
              <h1 className="text-white text-2xl font-semibold ml-4">Online Tools</h1>
            </Link>
            <Link to="/">
              <button className="text-white hover:bg-blue-600 px-4 py-2 rounded ml-4">
                Home
              </button>
            </Link>
          </div>
          <div className="hidden md:flex items-center">
            {/* drop down */}
            <Dropdown
      options={[
        { value: '/', label: 'Our Services' },
        { value: '/python', label: 'Python' },
        { value: '/pdftoimage', label: 'pdfToImage' },
        { value: '/JsonFormatter', label: 'JSON Formatter' },
        { value: '/converter', label: 'Binary to Decimal' },
        { value: '/complexitycalculator', label: 'Calculate Complexity' },
        { value: '/removebg', label: 'Image bg Remover' },

        

      ]}
      onChange={(selectedOptions) => handleSelectChange(selectedOptions)}
      placeholder="Our Services"
      values={[]}
    />
            <Link to="/about">
              <button className="text-white hover:bg-blue-600 px-4 py-2 rounded ml-4">
                About
              </button>
            </Link>
            {username && ( 
              <>
                <button onClick={handleLogout} className="text-white hover:bg-blue-600 px-4 py-2 rounded ml-4">
                  Logout
                </button>
                <div className="flex items-center ml-4">
                  <Avatar name={username} round={true} size="40" /> 
                  
                </div>
              </>
            )}
            {!username && (
              <>
                <Link to="/login">
                  <button onClick={closeSidebar} className="text-white hover:bg-blue-600 px-4 py-2 rounded ml-4">
                    Login
                  </button>
                </Link>
                <Link to="/signup">
                  <button onClick={closeSidebar} className="text-white hover:bg-blue-600 px-4 py-2 rounded block">
                    Signup
                  </button>
                </Link>
              </>
            )}
            <input
              type="text"
              placeholder="Search"
              className="text-white bg-blue-100 hover:bg-blue-600 px-2 py-1 rounded ml-2"
            />
          </div>
          <div className="md:hidden">
            <button
              onClick={changeSideBarState}
              className="text-white hover:bg-blue-600 px-4 py-2 rounded"
            >
              <RxHamburgerMenu />
            </button>
          </div>
        </div>
      </div>
      {isSidebarOpen && (
        <div className="md:hidden bg-blue-500">
          <div className="container mx-auto py-4">
          <Dropdown
          style={{backgroundColor:'black'}}
      options={[
        { value: '/', label: 'Our Services' },
        { value: '/python', label: 'Python' },
        { value: '/pdftoimage', label: 'pdfToImage' },
        { value: '/JsonFormatter', label: 'JSON Formatter' },
        { value: '/converter', label: 'Binary to Decimal' },
        { value: '/complexitycalculator', label: 'Calculate Complexity' },
      ]}
      onChange={(selectedOptions) => handleSelectChange(selectedOptions)}
      placeholder="Our Services"
      values={[]}
    />
            <Link to="/about">
              <button
                className="text-white hover:bg-blue-600 px-4 py-2 rounded mt-2 block"
                onClick={closeSidebar}
              >
                About
              </button>
            </Link>
            {username && ( // Display Logout button and Avatar if a user is logged in
              <>
                <button onClick={handleLogout} className="text-white hover:bg-blue-600 px-4 py-2 rounded block">
                  Logout
                </button>
                <div className="flex items-center ml-4">
                  <Avatar name={username} round={true} size="40" /> {/* Display Avatar */}
                
                </div>
              </>
            )}
            {!username && (
              <>
                <Link to="/login">
                  <button onClick={closeSidebar} className="text-white hover:bg-blue-600 px-4 py-2 rounded block">
                    Login
                  </button>
                </Link>
                <Link to="/signup">
                  <button onClick={closeSidebar} className="text-white hover:bg-blue-600 px-4 py-2 rounded block">
                    Signup
                  </button>
                </Link>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SideBar;

