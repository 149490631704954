import React, { useState , useEffect} from "react";
import axios from "axios";
import "tailwindcss/tailwind.css";
import './RemoveBg.css'
import { Helmet } from 'react-helmet';
const  RemoveBg =() => {
    // eslint-disable-next-line
    const containerStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
      };
    // eslint-disable-next-line
      const imageWrapperStyle = {
        position: 'relative',
        margin: '10px',
        maxWidth: '400px',
      };
    // eslint-disable-next-line
      const imgStyle = {
        maxWidth: '300px',
        height: 'auto',
        display: 'block',
      };
    
     
    const containerRef = React.useRef(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [resultUrl, setResultUrl] = useState(null);
    const [processing, setProcessing] = useState(false)
    const [copied, setCopied] = useState(false);
    const apiUrl = "https://api.remove.bg/v1.0/removebg";
    const handleImageFileChange = (event) => {
        setImageFile(event.target.files[0]);
        setImageUrl(null);
        setResultUrl(null);
    };
    const handleImageUrlChange = (e) => {
        setImageUrl(e.target.value);
        setImageFile(null);
        setResultUrl(null);
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        if (!imageFile && !imageUrl) {
            alert("Please provide an image file or URL.");
            return;
        }

        let formData;
        if (imageFile) {
            formData = new FormData();
            formData.append("image_file", imageFile);
        }
        else {
            formData = { image_url: imageUrl }
        }

        setProcessing(true);
        axios
            .post(apiUrl, formData, { headers: { "X-Api-Key": 'wFp7g2KujogrdoCpUhySA1yA' }, responseType: "blob" })
            .then((response) => {
                setResultUrl(URL.createObjectURL(response.data));
                containerRef.current.scrollIntoView({ behavior: 'smooth' });
            })
            .catch((error) => {
                console.error(error);
            }).finally(() => {
                setProcessing(false)
            })
    };

    function clearData() {
        setImageFile(null)
        setImageUrl(null)
        setProcessing(null)
        setResultUrl(null);
    }

    const handleDownload = () => {
        // create a new anchor element with the image URL
        const downloadLink = document.createElement('a');
        downloadLink.href = resultUrl;
        downloadLink.download = 'rmbg-image.jpg';
        document.body.appendChild(downloadLink);
        // simulate click event on the anchor element
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const handleShare = async () => {
        try {
            // use Web Share API if available
            if (navigator.share) {
                await navigator.share({
                    title: 'bg remove Image',
                    url: resultUrl,
                });
            } else {
                // fallback to copying the image URL to clipboard
                await navigator.clipboard.writeText(resultUrl);
                setCopied(true);
                setTimeout(() => setCopied(false), 3000);
            }
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
 
        const script = document.createElement("script");
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtag/js?id=G-9NPYJSM5SJ`;
        document.head.appendChild(script);
    
        script.onload = () => {
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            window.dataLayer.push(arguments);
          }
          gtag("js", new Date());
          gtag("config", "G-9NPYJSM5SJ");
        };
      }, []);
    
    return (
        <div className="overflow-hidden flex flex-col items-center">
            <Helmet>
                <title>Background Remover Online - toolsbio.com</title>
                <meta
                name="description"
                content="Remove background from images easily with our online background remover tool. Upload, process, and download your images without any hassle on toolsbio.com."
                />
                <meta name="keywords" content="background remover, remove background, image background removal, online tool, toolsbio.com, photo editing, transparent background, bgremover" />
                <meta name="author" content="Mehruddin Khan" />
                <meta name="robots" content="index, follow" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://toolsbio.com/bgremover" />
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2030818425983269" crossorigin="anonymous"></script>
            </Helmet>

            <div className="flex flex-col lg:flex-row gap-5 lg:p-20 w-full bg-gray-100" style={{ minHeight: 'calc(100vh - 11vh)' }}>

                <section className="flex justify-center item-center" style={{ flex: "0.5" }}>
                    <div className="max-w-7xl mx-auto px-4 flex flex-col justify-center item-center">
                        <h1 className="text-3xl lg:text-5xl font-bold my-8  mb-8 text-indigo-600 text-center">Remove Background</h1>

                        <h2 className="text-2xl font-medium mb-8 text-center">
                            Say goodbye to background hassles.
                        </h2>

                        <div className="bg-white p-4 rounded-lg shadow-lg">
                            <img className="h-60 w-full rounded" src={(imageFile ? URL.createObjectURL(imageFile) : imageUrl) || "https://hotpot.ai/images/site/ai/background_remover/teaser_400.jpg"} alt="" style={{ objectFit: "contain" }} />
                        </div>

                    </div>
                </section>
                <form onSubmit={handleSubmit} className="flex flex-col items-center justify-center mb-8 lg:w-3/5 mt-8" style={{ flex: "0.5" }}>
                <div className="relative mb-3 xl:w-96">
                    <input
                        className="peer m-0 block h-[58px] w-full rounded border border-solid border-neutral-300 bg-white bg-clip-padding py-4 px-3 text-base font-normal leading-tight text-neutral-700 ease-in-out placeholder:text-transparent focus:border-primary focus:bg-white focus:pt-[1.625rem] focus:pb-[0.625rem] focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:bg-neutral-800 dark:text-neutral-200 [&:not(:placeholder-shown)]:pt-[1.625rem] [&:not(:placeholder-shown)]:pb-[0.625rem] bg-white w-full"
                        id="image-url"
                        type="text"
                        placeholder="Image URL"
                        value={imageUrl}
                        onChange={handleImageUrlChange}
                    />
                    
                    </div>

                    <p className="text-gray-700 m-3">
                        or
                    </p>
                    <div class="flex items-center justify-center w-full px-4 drag-box" >
                        <label for="dropzone-file" class="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-white  hover:bg-blue-100">
                            <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                <svg aria-hidden="true" class="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p>

                            </div>
                            <input id="dropzone-file" type="file" class="hidden" onChange={handleImageFileChange} />
                        </label>
                    </div>
                    <div className="flex gap-5 item-center">
                        <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:shadow-outline mt-5">
                            {processing ? "Processing..." : "Remove Background"}
                        </button>
                        <button onClick={clearData} disabled={(!imageFile && !imageUrl) || (processing)} className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:shadow-outline mt-5">
                            Clear
                        </button>
                    </div>
                </form>
            </div>
            {resultUrl &&
                <div className="bg-white p-4 rounded-lg shadow-lg w-60vw mt-10" style={{
                    border: "1px solid #e2e8f0"
                }}>
                    <img className="h-96 w-full rounded w-60vw sm:w-100vw"
                        src={resultUrl}
                        alt=""
                        style={{
                            border: "2px solid #e2e8f0",
                            objectFit: "contain",
                            backgroundColor: '#fff',
                            backgroundImage: 'url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjkzNyA3LjkzOCIgaGVpZ2h0PSIzMCIgd2lkdGg9IjMwIj48cGF0aCBwYWludC1vcmRlcj0ic3Ryb2tlIGZpbGwgbWFya2VycyIgZD0iTS4wMTQuMDE0SDMuOTdWMy45N0guMDE0ek0zLjk3IDMuOTY4aDMuOTU0djMuOTU1SDMuOTd6IiBmaWxsPSIjZWVlZmYwIi8+PC9zdmc+)',
                            backgroundSize: '25px'
                        }}
                    />
                </div>
            }

            {resultUrl && <div className="flex justify-center item-center gap-5 my-6 bg-white p-5 w-full">
                <button
                    className="bg-blue-700 hover:bg-blue-600 text-white py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-600"
                    onClick={handleDownload}
                >
                    Download
                </button>

                <button
                    ref={containerRef}
                    className={`${copied ? 'bg-green-500' : 'bg-blue-700'
                        } hover:bg-blue-700 text-white py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-600`}
                    onClick={handleShare}
                >
                    {copied ? 'Copied!' : 'Share'}
                </button>
            </div>}
        </div>
    );
}
export default RemoveBg;