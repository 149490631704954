import { useContext, useEffect } from "react";
import { GeneralContext } from "../context/GeneralContextProvider.js";
import utils from "./utils.js";
import Popup from "./Popup.js";
import './style.css'
import Carousel from "./Carousel.js";
// import Fotter from '../fotter/fotter.js'
import { Helmet } from 'react-helmet';
const Utils = new utils();

const PdfToImage = () => {
    const {files, setFiles, popup, setPopup} = useContext(GeneralContext);

    const handleFile = (e) => {
        let filesArray = [...files];

        [...e.target.files].forEach((file) => {
            if (file.type === "image/jpeg" || file.type === "image/png") {
                filesArray.push(file);
            };
        });

        if (filesArray.length <= 20) {
            setFiles(filesArray);
        } else {
            setPopup({show: true, message: "Exceeded limit of 20 images.", timeout: 5});
        };  
    };

    const clearQueue = () => {
        if (files.length > 0) {
            setFiles(() => []);
            setPopup({show: true, message: "Queue cleared.", timeout: 5});
        };
    };

    const download = () => {
        if (files.length > 0) {
            setPopup({show: true, message: "Converting images to PDF...", timeout: 60})
            Utils.generatePDF(files).then(() => setPopup({show: true, message: "Download completed!", timeout: 5}))
        } else {
            setPopup({show: true, message: "Please upload images!", timeout: 5})
        };
    };
    useEffect(() => {
 
        const script = document.createElement("script");
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtag/js?id=G-9NPYJSM5SJ`;
        document.head.appendChild(script);
    
        script.onload = () => {
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            window.dataLayer.push(arguments);
          }
          gtag("js", new Date());
          gtag("config", "G-9NPYJSM5SJ");
        };
      }, []);

    return (
        <>
            {popup.show ? <Popup/> : ""}
            <div className="upload-conteiner">
                <Helmet>
                <title>PDF to Image Converter - toolsbio.com</title>
                <meta
                    name="description"
                    content="Convert PDF files to images with the online PDF to Image Converter on toolsbio.com."
                />
                <meta name="keywords" content="PDF to image converter, convert PDF to image, toolsbio.com, coding tools" />
                <meta name="author" content="mehruddin khan" />
                <meta name="robots" content="index, follow" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://toolsbio.com/pdftoimage" />
                {/* Add other meta tags as needed for SEO */}
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2030818425983269" crossorigin="anonymous"></script>

                </Helmet>

            <h3 className="text-3xl font-bold text-center text-blue-500 mt-14">Image to PDF Converter</h3>

                <div className="box">
                    <div className="wrapper">
                        <span className="text">+</span>
                        <p className="prompt">Click or drag and drop files here to upload</p>
                        <input id="file-upload" type={"file"} multiple={"multiple"} accept={"image/png, image/jpeg,image/jpg"} onChange={handleFile}/>

                    </div>
                </div>
                <Carousel files={files}/>
                <div className="button_container">
                    <button className="action clear_queue" onClick={clearQueue}>Clear Images</button>
                    <button className="action download" onClick={download}>Download [PDF]</button>
                </div>
                {/* <Info/> */}
            </div>
            {/* <Fotter/> */}
        </>
    );
};

export default PdfToImage;