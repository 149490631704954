import React, { useState , useEffect} from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { Helmet } from 'react-helmet';
// import SideBarSide from "../sidebar/sidebar";
const JsonFormatter = () => {

  const [inputJson, setInputJson] = useState("");
  const [formattedJson, setFormattedJson] = useState("");

  const handleConvertClick = () => {
    try {
      const parsedJson = JSON.parse(inputJson);
      const formattedText = JSON.stringify(parsedJson, null, 2);
      setFormattedJson(formattedText);
    } catch (error) {
      setFormattedJson("Invalid JSON");
     
      
    }
  };

  const handleCopyClick = () => {
    try {
      navigator.clipboard.writeText(formattedJson);
      if(formattedJson){
        toast.success("Formatted JSON copied to clipboard!");

      }
      if(formattedJson.length <=0){
        toast.error("Failed to copy formatted JSON.");

      }
    } catch (error) {
      setFormattedJson("Invalid JSON");
      if(formattedJson.length <=0){
        toast.error("Failed to copy formatted JSON.");

      }
    }
  };
  // analytics  json formatter
  useEffect(() => {
 
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-9NPYJSM5SJ`;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "G-9NPYJSM5SJ");
    };
  }, []);


  return (
    <div className="flex flex-col h-screen">
      <Helmet>
  <title>JSON Formatter - toolsbio.com</title>
  <meta
    name="description"
    content="Format and beautify JSON data online with the JSON Formatter tool on toolsbio.com."
  />
  <meta name="keywords" content="JSON formatter, JSON beautifier, toolsbio.com, coding tools, data formatting" />
  <meta name="author" content="mehruddin khan" />
  <meta name="robots" content="index, follow" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <link rel="canonical" href="https://toolsbio.com/JsonFormatter" />
  <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2030818425983269" crossorigin="anonymous"></script>

  {/* Add other meta tags as needed for SEO */}
</Helmet>

      <ToastContainer position="top-right" autoClose={2000} />
      {/* Button Row */}
      <div className="bg-gray-800 text-white p-2 flex items-center">
        <button
          className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg mr-2"
          onClick={handleConvertClick}
        >
          Convert to JSON
        </button>
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg"
          onClick={handleCopyClick}
        >
          Copy Formatted JSON
        </button>
      </div>

      {/* Two Columns */}
      <div className="flex-1 flex json-editor">
      {/* <SideBarSide></SideBarSide> */}
        {/* Left Column */}
        <div className="w-1/2 bg-gray-200 p-4 json-editor-input">
          <h2 className="text-xl font-semibold mb-2">Original Code</h2>
          <textarea
            className="w-full h-full p-4 border rounded-lg"
            placeholder="Enter JSON code here"
            value={inputJson}
            onChange={(e) => setInputJson(e.target.value)}
          />
        </div>
        {/* Right Column */}
        <div className="w-1/2 bg-gray-100 p-4 py-editor">
          <h2 className="text-xl font-semibold mb-2">Formatted Code</h2>
          <SyntaxHighlighter 
           className="heiglighter_height"
            language="json"
            style={a11yDark}
            showLineNumbers={true}
          >
            {formattedJson}
          </SyntaxHighlighter>
        </div>
      </div>
    </div>
  );
};

export default JsonFormatter;
