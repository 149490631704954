import React , {useEffect} from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Footer from "../fotter/fotter";

const About = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-9NPYJSM5SJ`;
    document.head.appendChild(script);
    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "G-9NPYJSM5SJ");
    };
  }, []);

  return (
    <div className="bg-gray-100 min-h-screen">
   
      {/* Helmet for SEO */}
      <Helmet>
  <title>About - toolsbio.com</title>
  <meta
    name="description"
    content="Learn more about toolsbio.com, your go-to place for handy online utilities. Discover our mission and commitment to simplifying tasks with a collection of online tools."
  />
  <meta name="keywords" content="about us, toolsbio.com, online utilities, mission, coding tools" />
  <meta name="author" content="mehruddin khan" />
  <meta name="robots" content="index, follow" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <link rel="canonical" href="https://toolsbio.com/about" />
  {/* Add other meta tags as needed for SEO */}

  
</Helmet>


      {/* Header */}
      <header className="bg-blue-500 text-white py-10">
        <div className="container mx-auto text-center">
          <h1 className="text-4xl font-semibold">About Online Tools</h1>
          <p className="mt-2 text-lg">Your Go-To Place for Handy Online Utilities</p>
        </div>
      </header>

      {/* Main Content */}
      <section className="container mx-auto py-12">
        <div className="max-w-3xl mx-auto">
          <h2 className="text-2xl font-semibold mb-4 text-center">Our Mission</h2>
          <p className="text-gray-700">
            We are committed to providing you with a collection of online tools
            that simplify your tasks and make your life easier. Whether it's
            programming in Python, formatting JSON data, or converting between
            binary and decimal, we've got you covered.
          </p>
        </div>
      </section>

      {/* Call to Action */}
      <section className="bg-blue-500 text-white py-12">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-semibold">Get Started Today</h2>
          <p className="mt-2 text-lg">
            Explore our tools and experience the convenience firsthand.
          </p>
          <Link to="/">
            <button className="bg-white text-blue-500 hover:bg-blue-600 px-6 py-3 rounded-full mt-4">
              Explore Tools
            </button>
          </Link>
        </div>
      </section>

      {/* Footer */}
      <div className="">
        <Footer />
      </div>
    </div>
  );
};

export default About;
